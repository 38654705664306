<template>
  <v-row fluid elevation="10">
    <v-col cols="12">
      <v-row
        class="sqr_button"
        align="center"
        justify="center"
        v-ripple="{ center: true }"
      >
        <v-col cols="2" class="ma-0 pa-1"
          ><v-img :src="img" max-height="40px" contain>
      
            
            </v-img
        ></v-col>
        <v-col cols="7" justify="left" style=""><slot style="font-size: bolder" />
        
              <div style="position:absolute; right:23px; bottom:8px;" v-if="success">
          <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
        </div>
        <div style="position:absolute; right:23px;bottom:8px;" v-if="failed">
          <v-icon color="red">mdi-close-circle-outline</v-icon>
        </div>

        </v-col>
        <v-col cols="2">
        <v-avatar
        pill
        v-if="new_count!='0'"
        class=""
        size="33"
        color="red"
        style="color:white"
        >{{new_count}}</v-avatar>
        </v-col> 
         <v-col cols="1">
        <div style="position:absolute; right:10px;margin-top: -7px;" v-if="1">
          <v-img src="img/s_next.png"></v-img>
        </div>
        </v-col>
      </v-row>
    </v-col>

    <v-overlay v-if="overlay"></v-overlay>
  </v-row>
</template>

<script>
export default {
  component: "btnsqr",
  name: "btnsqr",
  props: {
    img: {
      type: String,
      default: "",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    new_count:{
        type:String,
        default:"0"
    }
  },
  methods: {},
};
</script>